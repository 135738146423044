// Hero background
import herobglg2xavif from './images/hero_bg.lg.2x.avif';
import herobglg2xwebp from './images/hero_bg.lg.2x.webp';
import herobglg3xavif from './images/hero_bg.lg.3x.avif';
import herobglg3xwebp from './images/hero_bg.lg.3x.webp';
import herobglgavif from './images/hero_bg.lg.avif';
import herobglgwebp from './images/hero_bg.lg.webp';
import herobgmd2xavif from './images/hero_bg.md.2x.avif';
import herobgmd2xwebp from './images/hero_bg.md.2x.webp';
import herobgmd3xavif from './images/hero_bg.md.3x.avif';
import herobgmd3xwebp from './images/hero_bg.md.3x.webp';
import herobgmdavif from './images/hero_bg.md.avif';
import herobgmdwebp from './images/hero_bg.md.webp';
import herobgsm2xavif from './images/hero_bg.sm.2x.avif';
import herobgsm2xwebp from './images/hero_bg.sm.2x.webp';
import herobgsm3xavif from './images/hero_bg.sm.3x.avif';
import herobgsm3xwebp from './images/hero_bg.sm.3x.webp';
import herobgxl2xavif from './images/hero_bg.xl.2x.avif';
import herobgxl2xwebp from './images/hero_bg.xl.2x.webp';
import herobgxl3xavif from './images/hero_bg.xl.3x.avif';
import herobgxl3xwebp from './images/hero_bg.xl.3x.webp';
import herobgxlavif from './images/hero_bg.xl.avif';
import herobgxlwebp from './images/hero_bg.xl.webp';
import herobgxs2xavif from './images/hero_bg.xs.2x.avif';
import herobgxs2xwebp from './images/hero_bg.xs.2x.webp';
import herobgxs3xavif from './images/hero_bg.xs.3x.avif';
import herobgxs3xwebp from './images/hero_bg.xs.3x.webp';
// Connected background
import connectedbgmd2xavif from './images/connected_bg.md.2x.avif';
import connectedbgmd2xwebp from './images/connected_bg.md.2x.webp';
import connectedbgmd3xavif from './images/connected_bg.md.3x.avif';
import connectedbgmd3xwebp from './images/connected_bg.md.3x.webp';
import connectedbgmdavif from './images/connected_bg.md.avif';
import connectedbgmdwebp from './images/connected_bg.md.webp';
import connectedbgsm2xavif from './images/connected_bg.sm.2x.avif';
import connectedbgsm2xwebp from './images/connected_bg.sm.2x.webp';
import connectedbgsm3xavif from './images/connected_bg.sm.3x.avif';
import connectedbgsm3xwebp from './images/connected_bg.sm.3x.webp';
// Homekits background
import homekitsbglg2xavif from './images/homekits_bg.lg.2x.avif';
import homekitsbglg3xavif from './images/homekits_bg.lg.3x.avif';
import homekitsbglgavif from './images/homekits_bg.lg.avif';
import homekitsbgmd2xavif from './images/homekits_bg.md.2x.avif';
import homekitsbgmd2xwebp from './images/homekits_bg.md.2x.webp';
import homekitsbgmd3xavif from './images/homekits_bg.md.3x.avif';
import homekitsbgmd3xwebp from './images/homekits_bg.md.3x.webp';
import homekitsbgxs2xavif from './images/homekits_bg.xs.2x.avif';
import homekitsbgxs3xavif from './images/homekits_bg.xs.3x.avif';
// How it works background
import howitworksbgavif from './images/howitworks_bg.avif';
import howitworksbglg2xavif from './images/howitworks_bg.lg.2x.avif';
import howitworksbglg2xwebp from './images/howitworks_bg.lg.2x.webp';
import howitworksbglg3xavif from './images/howitworks_bg.lg.3x.avif';
import howitworksbglg3xwebp from './images/howitworks_bg.lg.3x.webp';
import howitworksbglgavif from './images/howitworks_bg.lg.avif';
import howitworksbglgwebp from './images/howitworks_bg.lg.webp';
import howitworksbgmd2xavif from './images/howitworks_bg.md.2x.avif';
import howitworksbgmd3xavif from './images/howitworks_bg.md.3x.avif';
import howitworksbgxs2xavif from './images/howitworks_bg.xs.2x.avif';
import howitworksbgxs2xwebp from './images/howitworks_bg.xs.2x.webp';
import howitworksbgxs3xavif from './images/howitworks_bg.xs.3x.avif';
import howitworksbgxs3xwebp from './images/howitworks_bg.xs.3x.webp';
// On demand videos
import ondemandvideossm2xavif from './images/ondemandvideos.sm.2x.avif';
import ondemandvideossm3xavif from './images/ondemandvideos.sm.3x.avif';
import ondemandvideosmd2xavif from './images/ondemandvideos.md.2x.avif';
import ondemandvideosmd3xavif from './images/ondemandvideos.md.3x.avif';
import ondemandvideoslgavif from './images/ondemandvideos.lg.avif';
import ondemandvideoslg2xavif from './images/ondemandvideos.lg.2x.avif';
// On demand logos
import ondemandlogossm2xavif from './images/ondemandlogos.sm.2x.avif';
import ondemandlogossm3xavif from './images/ondemandlogos.sm.3x.avif';
import ondemandlogosmdavif from './images/ondemandlogos.md.avif';
import ondemandlogoslg2xavif from './images/ondemandlogos.lg.2x.avif';
import ondemandlogoslg3xavif from './images/ondemandlogos.lg.3x.avif';
// Fitness center logos
import fitnesslogosxs2xavif from './images/fitness_logos.xs.2x.avif';
import fitnesslogosxs2xwebp from './images/fitness_logos.xs.2x.webp';
import fitnesslogosxs3xavif from './images/fitness_logos.xs.3x.avif';
import fitnesslogosxs3xwebp from './images/fitness_logos.xs.3x.webp';
import fitnesslogosmd2xavif from './images/fitness_logos.md.2x.avif';
import fitnesslogosmd2xwebp from './images/fitness_logos.md.2x.webp';
import fitnesslogosmd3xavif from './images/fitness_logos.md.3x.avif';
import fitnesslogosmd3xwebp from './images/fitness_logos.md.3x.webp';
import fitnesslogosmdavif from './images/fitness_logos.md.avif';
import fitnesslogosmdwebp from './images/fitness_logos.md.webp';

export const heroBg = [
	herobglg2xavif,
	herobglg2xwebp,
	herobglg3xavif,
	herobglg3xwebp,
	herobglgavif,
	herobglgwebp,
	herobgmd2xavif,
	herobgmd2xwebp,
	herobgmd3xavif,
	herobgmd3xwebp,
	herobgmdavif,
	herobgmdwebp,
	herobgsm2xavif,
	herobgsm2xwebp,
	herobgsm3xavif,
	herobgsm3xwebp,
	herobgxl2xavif,
	herobgxl2xwebp,
	herobgxl3xavif,
	herobgxl3xwebp,
	herobgxlavif,
	herobgxlwebp,
	herobgxs2xavif,
	herobgxs2xwebp,
	herobgxs3xavif,
	herobgxs3xwebp,
];

export const fitnessLogos = [
	fitnesslogosxs2xavif,
	fitnesslogosxs2xwebp,
	fitnesslogosxs3xavif,
	fitnesslogosxs3xwebp,
	fitnesslogosmd2xavif,
	fitnesslogosmd2xwebp,
	fitnesslogosmd3xavif,
	fitnesslogosmd3xwebp,
	fitnesslogosmdavif,
	fitnesslogosmdwebp,
];

export const howItWorksBg = [
	howitworksbgavif,
	howitworksbglg2xavif,
	howitworksbglg2xwebp,
	howitworksbglg3xavif,
	howitworksbglg3xwebp,
	howitworksbglgavif,
	howitworksbglgwebp,
	howitworksbgmd2xavif,
	howitworksbgmd3xavif,
	howitworksbgxs2xavif,
	howitworksbgxs2xwebp,
	howitworksbgxs3xavif,
	howitworksbgxs3xwebp,
];

export const onDemandVideosBg = [
	ondemandvideossm2xavif,
	ondemandvideossm3xavif,
	ondemandvideosmd2xavif,
	ondemandvideosmd3xavif,
	ondemandvideoslgavif,
	ondemandvideoslg2xavif
];

export const onDemandLogos = [
	ondemandlogossm2xavif,
	ondemandlogossm3xavif,
	ondemandlogosmdavif,
	ondemandlogoslg2xavif,
	ondemandlogoslg3xavif
];

export const homeKitsBg = [
	homekitsbglg2xavif,
	homekitsbglg3xavif,
	homekitsbglgavif,
	homekitsbgmd2xavif,
	homekitsbgmd2xwebp,
	homekitsbgmd3xavif,
	homekitsbgmd3xwebp,
	homekitsbgxs2xavif,
	homekitsbgxs3xavif,
];

export const connectedBg = [
	connectedbgmd2xavif,
	connectedbgmd2xwebp,
	connectedbgmd3xavif,
	connectedbgmd3xwebp,
	connectedbgmdavif,
	connectedbgmdwebp,
	connectedbgsm2xavif,
	connectedbgsm2xwebp,
	connectedbgsm3xavif,
	connectedbgsm3xwebp,
];
